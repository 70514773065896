import { inject } from '@angular/core';
import { CanActivateFn, createUrlTreeFromSnapshot, Router } from '@angular/router';
import { PermissionGuardConfig } from '@core/models';
import { PermissionService } from '@core/services';

/**
 * Factory function to create a permission guard based on the provided configuration.
 * This guard checks if the user has the necessary permissions, and if not, it handles redirection
 * either relatively or absolutely. If no redirect path is provided, the default redirect is to '/cpl'.
 *
 * @param {PermissionGuardConfig} config - Configuration object for the permission guard.
 * @returns {CanActivateFn} A function that determines if the route can be activated.
 */
export const createPermissionGuard = (config: PermissionGuardConfig): CanActivateFn => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (route, _) => {
    const permissionService = inject(PermissionService);
    const router = inject(Router);

    // Check if the user has the required permissions based on the 'permissionCheckType'
    const hasPermission =
      config.permissionCheckType === 'some'
        ? permissionService.hasAnyPermission(config.requiredPermissions)
        : permissionService.hasAllPermissions(config.requiredPermissions);

    // If the user lacks the necessary permissions, handle redirection
    if (!hasPermission) {
      if (config.redirectPath) {
        if (config.redirectType === 'relative') {
          // Create a relative URL tree and navigate to it
          const urlTree = createUrlTreeFromSnapshot(route, [config.redirectPath]);
          router.navigate([urlTree.toString()]);
          return false;
        } else {
          // Navigate to an absolute redirect path
          return router.parseUrl(config.redirectPath);
        }
      } else {
        // Default redirect to '/cpl' if no redirect path is provided
        router.navigate(['/cpl']);
        return false;
      }
    }

    // Return true if the user has the required permissions
    return hasPermission;
  };
};
