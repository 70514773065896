import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { jwtCredentials } from '../signals';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const loginGuard: CanActivateFn = (_route, _state) => {
  const router = inject(Router);
  if (jwtCredentials()?.accessToken) {
    return router.createUrlTree(['/cpl']);
  } else {
    return true;
  }
};
