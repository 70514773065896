import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { PermissionService } from '@core/services';
import { routePermissions } from '@shared/layout';

/**
 * This is the main route permission guard that checks permissions for all sidebar routes
 * using the route permissions merged in the navigation utility.
 *
 * The guard looks for a key-value pair structure to fetch the required permissions
 * and determines whether to allow navigation to the main route.
 */
export const mainRoutePermissionGuard: CanActivateFn = async (route, state) => {
  const permissionService = inject(PermissionService);
  const router = inject(Router);
  const requiredPermissions = routePermissions[state.url];

  const hasPermission = permissionService.hasAnyPermission(requiredPermissions);
  if (!hasPermission) {
    router.navigate(['/cpl']);
    return false;
  }

  return true;
};
